<template>
  <d-table
    identifier="category"
    :tablefields="tableFields"
    :items="items"
    :per-page="itemsPerPage"
    :totalRows="totalRows"
    :current-page="currentPage"
    :is-busy="isBusy"
    :tr-cursor-pointer="false"
    @current-page:update="onPaginationUpdate"
    @on:category:delete="deleteCategory"
    @on:data-table-btn:edit="onCategoryEdit"
  >
  </d-table>
</template>
<script>
import {SUCCESS} from "@plugins/flash";
import {
  deleteBlockPriceCategory,
  getAllBlockPriceCategories
} from "@api/doinsport/services/timetable/blocks/price/category/category.api";

export default {
  data: () => ({
    items: [],
    filter: '',
    isBusy: false,
    itemsPerPage: 5,
    currentPage: 1,
    totalRows: 1,
  }),
  props: {
    reload: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    reload: function () {
      this.loadBlockPriceCategories();
    }
  },
  computed: {
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    tableFields() {
      return [
        {
          key: "name",
          label: this.$t("views.playground.index.parameters.categories.table.name"),
          sortable: true
        },
        {
          key: "type",
          class: this.alignCellsToLeft,
          label: this.$t("views.playground.index.parameters.categories.table.type")
        },
        {
          key: "actions", label: this.$t("general.actions.actions")
        },
      ];
    },
  },
  methods: {
    onCategoryEdit(category) {
      this.$emit('on:category:edit', category.id);
    },
    deleteCategory(category) {
      deleteBlockPriceCategory(category.id)
        .then((response) => {
          this.$flash(null, this.$t('views.playground.index.parameters.categories.deleted'), 5000, SUCCESS);

          this.$emit('reset:category-form');
          this.loadBlockPriceCategories();
        })
    },
    onPaginationUpdate(page) {
      this.currentPage = page;
    },
    loadBlockPriceCategories() {
      this.isBusy = true;

      getAllBlockPriceCategories()
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];

          const tmpItems = [];
          this.items = [];

          for (const category of response.data['hydra:member']) {
            const item = {
              id: category.id,
              name: category.name,
              club: category.club,
              type: this.$t('components.form.playground.block-price.activityType.' + category.activityType),
              actions: category.club ? ['edit', 'delete'] : []
            };

            if (item.club !== null) {
              this.items.push(item);
            } else {
              tmpItems.push(item);
            }
          }

          this.items = [...this.items, ...tmpItems];

        }).finally(() => {
        this.isBusy = false;
      })
    }
  },
  created() {
    this.loadBlockPriceCategories();
  }
}
</script>
